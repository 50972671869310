import React from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faHome, faPhone } from "@fortawesome/free-solid-svg-icons"

import SEO from "../components/seo/SEO"
import MapContainer from "../components/googleMap/GoogleMap"
import Layout from "../layout/layout2"
import { Container } from "../utils/utils"

const KontaktWrapper = styled.div`
  display: grid;
  padding-bottom: 5vh;
  margin: 2rem 0 2rem 0;
  grid-template-columns: repeat(2, 1fr);

  ${down("xs")} {
    grid-template-columns: repeat(1, auto);
    margin: 0;
  }

  > div {
    justify-self: center;
    margin-top: 5vh;

    ${down("xs")} {
      justify-self: left;
    }
  }
`

const H = styled.h6`
  color: ${({ theme }) => theme.color.base};
`

const Label = styled.div`
  color: ${({ theme }) => theme.color.baseDark};
  align-self: center;
  font-weight: bold;
  font-size: 14px;
`

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.color.baseDark};
  font-size: 14px;
`

const KontaktItem = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: start;

  && div:nth-child(1) {
    width: 3rem;
  }

  p {
    word-break: break-all;
  }

  + div {
    margin-top: 1rem;
  }
`

const FreeMap = styled.iframe`
  filter: grayscale(100%);
  border-width: 0;
`

const Kontakt = ({ data }) => {
  const godziny = data.datoCmsKontakt.godzinyOtwarcia
  const kontakt = data.datoCmsKontakt.daneTeleadresowe[0]

  return (
    <div>
      <SEO title="Kontakt" slug="/kontakt" />
      <Layout>
        <Container>
          <KontaktWrapper>
            <div>
              <H>Dane teleadresowe</H>
              <KontaktItem>
                <div>
                  <Icon icon={faHome} />
                </div>
                <div>
                  <p>
                    ul. {kontakt.ulica} {kontakt.nrBudnku}
                  </p>
                  <p>
                    {kontakt.kod} {kontakt.miasto}
                  </p>
                </div>
              </KontaktItem>
              <KontaktItem>
                <div>
                  <Icon icon={faPhone} />
                </div>
                <div>
                  <p>+48 {kontakt.nrTel}</p>
                </div>
              </KontaktItem>
              <KontaktItem>
                <div>
                  <Icon icon={faEnvelope} />
                </div>
                <div>
                  <p>{kontakt.email}</p>
                </div>
              </KontaktItem>
            </div>

            <div>
              <H>Godziny otwarcia</H>
              {godziny.map(godzina => {
                return (
                  <KontaktItem key={godzina.id}>
                    <Label>{godzina.day}</Label>
                    <div>
                      <p>
                        {godzina.godzinaOd} - {godzina.godzinaDo}
                      </p>
                    </div>
                  </KontaktItem>
                )
              })}
            </div>
          </KontaktWrapper>
        </Container>
        <FreeMap
          width="100%"
          height="350"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=19.126360416412357%2C50.81703917383102%2C19.133441448211673%2C50.819869097597746&amp;layer=mapnik&amp;marker=50.818455851716074%2C19.12990093231201"
        ></FreeMap>
        {/* <MapContainer /> */}
      </Layout>
    </div>
  )
}
export default Kontakt

export const query = graphql`
  query {
    datoCmsKontakt {
      godzinyOtwarcia {
        godzinaDo
        godzinaOd
        day
        id
      }
      daneTeleadresowe {
        ulica
        nrBudnku
        miasto
        kod
        nrTel
        email
      }
    }
  }
`
